import React, { useState, Fragment, useEffect } from 'react';
import {
  Page,
  Card,
  Stack,
  ProgressBar,
  TextStyle,
  Layout,
  ResourceItem,
  ResourceList,
  Toast,
  Frame,
  Badge,
  Filters,
  Link,
  Icon,
  Tag
} from '@shopify/polaris';

import { useNavigate, useParams } from "react-router-dom"

import './App.css';
import { AppFrame } from './AppFrame';
import { atom, selectorFamily, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { aFetch } from './Index';
import { AddProductMajor } from '@shopify/polaris-icons';
import { legalAgreedAtAtom } from './License';


function map(input: number, in_min: number, in_max: number, out_min: number, out_max: number) {
  return (input - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export function CustomStar(props: any) {

  var percentage = 100

  if (props.percentage != undefined) {
    percentage = props.percentage
  }

  var color = "#F8BE00"
  if (props.color != undefined) {
    color = props.color
  }


  let gradient_id = (Math.random() + 1).toString(36).substring(7);

  return (

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: props.size + "px", height: props.size + "px" }} width={props.size} height={props.size} key={gradient_id}>
      <defs>
        <linearGradient id={gradient_id}>
          <stop offset="0%" stopOpacity="1" stopColor="var(--color-star)" key={1} />
          <stop id={`fill-stop-1-${gradient_id}`} offset={`${percentage}%`} stopOpacity="1" stopColor="var(--color-star)" key={2} />
          <stop id={`fill-stop-0-${gradient_id}`} offset={`${percentage}%`} stopOpacity="0" stopColor="var(--color-star)" key={3} />
          <stop offset="100%" stopOpacity="0" stopColor="var(--color-star)" key={4} />
        </linearGradient>

      </defs>
      <path
        width="24"
        height="24"
        d="M8.34061 8.99999C8.74063 8.99999 9.10217 8.76159 9.25975 8.39391L11.5404 3.07233C11.7136 2.66828 12.2864 2.66827 12.4596 3.07233L14.7403 8.39391C14.8978 8.76159 15.2594 8.99999 15.6594 8.99999H20.7929C21.2384 8.99999 21.4614 9.53856 21.1465 9.85354L17.4004 13.5996C17.152 13.848 17.0521 14.2085 17.1373 14.5493L18.7158 20.8633C18.8226 21.2905 18.365 21.6371 17.9827 21.4187L12.4961 18.2835C12.1887 18.1078 11.8113 18.1078 11.5039 18.2835L6.01732 21.4187C5.635 21.6371 5.17738 21.2905 5.28418 20.8633L6.86268 14.5493C6.94787 14.2085 6.84803 13.848 6.59964 13.5996L2.85356 9.85355C2.53857 9.53856 2.76166 8.99999 3.20711 8.99999H8.34061Z"
        fill={"url(#" + gradient_id + ")"}
        strokeWidth="2"
        className='text-star stroke-current'
        strokeLinejoin="round"
      />
    </svg>
  )
}


// export function Star(props: any) {
//   return (
//     <img style={{ height: props.size ? props.size : "20px" }} className={props.color ? props.color : "yellow"} src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIyMXB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgd2lkdGg9IjIwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yOTYuMDAwMDAwLCAtNDIyLjAwMDAwMCkiPjxnIGlkPSJzdGFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOTYuMDAwMDAwLCA0MjIuNTAwMDAwKSI+PHBhdGggZD0iTTEwLDE1LjI3MyBMMTYuMTgsMTkgTDE0LjU0NSwxMS45NzEgTDIwLDcuMjQ0IEwxMi44MDksNi42MjcgTDEwLDAgTDcuMTkxLDYuNjI3IEwwLDcuMjQ0IEw1LjQ1NSwxMS45NzEgTDMuODIsMTkgTDEwLDE1LjI3MyBaIiBpZD0iU2hhcGUiLz48L2c+PC9nPjwvZz48L3N2Zz4=" />
//   )
// }

// export function HalfStar(props: any) {
//   return (
//     <img style={{ height: props.size ? props.size : "20px" }} className={props.color ? props.color : "yellow"} src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIyMHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjIwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMzguMDAwMDAwLCAtNDIyLjAwMDAwMCkiPjxnIGlkPSJzdGFyLWhhbGYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMzOC4wMDAwMDAsIDQyMi41MDAwMDApIj48cGF0aCBkPSJNMjAsNi43NDQgTDEyLjgwOSw2LjEyNyBMMTAsLTAuNSBMNy4xOTEsNi4xMjcgTDAsNi43NDQgTDUuNDU1LDExLjQ3MSBMMy44MiwxOC41IEwxMCwxNC43NzIgTDE2LjE4LDE4LjQ5OSBMMTQuNTQ1LDExLjQ3IEwyMCw2Ljc0NCBMMjAsNi43NDQgWiBNMTAsMTIuODk2IEwxMCwzLjU5NSBMMTEuNzEsNy42MzEgTDE2LjA5LDguMDA3IEwxMi43NjgsMTAuODg1IEwxMy43NjQsMTUuMTY2IEwxMCwxMi44OTYgTDEwLDEyLjg5NiBaIiBpZD0iU2hhcGUiLz48L2c+PC9nPjwvZz48L3N2Zz4=" />
//   )
// }

// export function EmptyStar(props: any) {
//   return (
//     <img style={{ height: props.size ? props.size : "20px" }} className={props.color ? props.color : "yellow"} src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIyMXB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgd2lkdGg9IjIwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zODAuMDAwMDAwLCAtNDIyLjAwMDAwMCkiPjxnIGlkPSJzdGFyLW91dGxpbmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM4MC4wMDAwMDAsIDQyMi41MDAwMDApIj48cGF0aCBkPSJNMjAsNy4yNDQgTDEyLjgwOSw2LjYyNyBMMTAsMCBMNy4xOTEsNi42MjcgTDAsNy4yNDQgTDUuNDU1LDExLjk3MSBMMy44MiwxOSBMMTAsMTUuMjcyIEwxNi4xOCwxOSBMMTQuNTQ1LDExLjk3MSBMMjAsNy4yNDQgTDIwLDcuMjQ0IFogTTEwLDEzLjM5NiBMNi4yMzcsMTUuNjY2IEw3LjIzMywxMS4zODUgTDMuOTEsOC41MDcgTDguMjksOC4xMzEgTDEwLDQuMDk1IEwxMS43MSw4LjEzMSBMMTYuMDksOC41MDcgTDEyLjc2OCwxMS4zODUgTDEzLjc2NCwxNS42NjYgTDEwLDEzLjM5NiBMMTAsMTMuMzk2IFoiIGlkPSJTaGFwZSIvPjwvZz48L2c+PC9nPjwvc3ZnPg==" />
//   )
// }


export function StarAndProgress(props: any) {

  return (
    <div style={{ "clear": "both" }}>
      <span style={{ width: "100px", float: "left", textAlign: "right" }} >
        {Array(props.starCount).fill(0).map((_, i) => (<CustomStar size={16} color="gray" />))}
      </span>

      <div style={{ marginLeft: "105px", height: "20px", paddingTop: "5px" }}>
        <ProgressBar size={"small"} progress={props.progress} />
      </div>
    </div>)

}
export function ReviewsHistogram(props: any) {

  var max = Math.max(props.rating_histogram[4], props.rating_histogram[3], props.rating_histogram[2], props.rating_histogram[1], props.rating_histogram[0])
  if (max == 0) {
    max = 1
  }
  return (
    props.rating_histogram ? (
      <div style={{ width: "100%", whiteSpace: "nowrap", position: "relative", overflow: "hidden" }}>
        <StarAndProgress starCount={5} progress={(props.rating_histogram[4] / max) * 100} />
        <StarAndProgress starCount={4} progress={(props.rating_histogram[3] / max) * 100} />
        <StarAndProgress starCount={3} progress={(props.rating_histogram[2] / max) * 100} />
        <StarAndProgress starCount={2} progress={(props.rating_histogram[1] / max) * 100} />
        <StarAndProgress starCount={1} progress={(props.rating_histogram[0] / max) * 100} />
      </div>) : (<Fragment />)
  )
}


export function StarsBadge(props: any) {

  return (
    <div style={{ display: "inline-block", height: props.size ? props.size : "20px", whiteSpace: "nowrap" }}>
      {Array(Math.floor(props.count)).fill(1).map((x, y) => (<CustomStar key={y} size={props.size ? props.size : "20px"} />))}
      {Math.ceil(props.count) != Math.floor(props.count) ? <CustomStar percentage={50} size={props.size ? props.size : "20px"} /> : <Fragment />}
      {Array(Math.floor(5 - props.count)).fill(1).map((x, y) => (<CustomStar percentage={0} key={y} size={props.size ? props.size : "20px"} />))}
    </div>
  )
}



const productQuery = selectorFamily({
  key: 'Product',
  get: (id) => async () => {


    return aFetch(`/api/products/${id?.toString()}/`).then(
      response => {
        return response.json()
      }
    )
  },
});


export function Product() {

  let { id } = useParams();



  const product = useRecoilValue(productQuery(id));

  const [legalAgreedAt, setLegalAgreedAt] = useRecoilState(legalAgreedAtAtom);

  let navigate = useNavigate();
  useEffect(() => {

    if (legalAgreedAt == "false") {
      navigate("/license")
    }

  }, [])





  return (

    <Page title={product.name} divider={false}
      breadcrumbs={[{ onAction: () => { navigate("/products/") } }]}

    >

      {/* <TitleBar title={product.name} breadcrumbs={[{ content: "Products", onAction: () => navigate("/products", { replace: true }) }]} /> */}

      <Layout>
        <Layout.Section oneHalf>
          <Card sectioned={true}>
            <Stack distribution="fillEvenly">
              <div style={{ textAlign: 'center', width: "50%" }}>

                <div style={{ fontSize: "46px", fontWeight: "800", color: "#555", padding: "38px 0px", clear: 'both', textAlign: 'center' }}>{product.star_rating.toFixed(1)}</div>

                <StarsBadge count={product.star_rating} />
              </div>
              <div style={{ textAlign: "center" }}>

                <ReviewsHistogram  {...product} />

                <h2 style={{ fontWeight: 70, fontSize: "1.1rem" }}>{`${product.review_count} reviews`}</h2>
              </div>

            </Stack>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card sectioned={true}>
            <TextStyle variation="strong">Customers mentioned</TextStyle><br />
            {Object.entries(JSON.parse(product.keyword_counts)).map((elem, value) => {
              if (elem[0] != "" && elem[1] != 0)
                return <span style={{ margin: ".25rem", display: "inline-block" }}><Badge>{elem[0] + " (" + elem[1] + ")"}</Badge></span>
            })}

          </Card>
        </Layout.Section>
      </Layout>
      <br />

    </Page>

  )
}


export function SuspendedAppFrame(props: any) {

  return (
    <AppFrame>
      <React.Suspense fallback={<div></div>} >
        {props.children}
      </React.Suspense>
    </AppFrame>
  )
}


export const productsQueryState = atom({
  key: 'productsQuery',
  default: "",
});

export const productsOffsetState = atom({
  key: 'productsOffset',
  default: 0,
});

const paginatedProductsQuery = selectorFamily({
  key: 'Products',
  get: (offset: number) => async ({ get }) => {
    return aFetch(`/api/products/?limit=7&offset=${String(offset)}&filter=${get(productsQueryState)}`).then(
      response => {
        return response.json()
      }
    )


  },
});

function copyToClipboard(text: any) {
  if (((window as unknown) as any).clipboardData && ((window as unknown) as any).clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return ((window as unknown) as any).clipboardData.setData("Text", text);

  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy");  // Security exception may be thrown by some browsers.
    }
    catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    }
    finally {
      document.body.removeChild(textarea);
    }
  }
}

export function Products() {

  const [productsOffset, setProductsOffset] = useRecoilState(productsOffsetState);
  const [productsQueryString, setProductsQueryString] = useRecoilState(productsQueryState);
  const products = useRecoilValueLoadable(paginatedProductsQuery(productsOffset));
  const [showToast, setShowToast] = useState(false)
  const initiallySortedRows = [] as any[];

  const [legalAgreedAt, setLegalAgreedAt] = useRecoilState(legalAgreedAtAtom);

  let navigate = useNavigate();
  useEffect(() => {

    if (legalAgreedAt == "false") {
      navigate("/license")
    }

  }, [])

  const filterControl = (
    <Filters
      queryValue={productsQueryString}
      onQueryChange={(value) => {
        setProductsQueryString(value);
      }}

      onQueryClear={() => { setProductsQueryString(""); }}
      onClearAll={() => { }} filters={[]}    >

    </Filters>
  );



  return (
    <Frame>

      <Page title="Products" divider={false}
        secondaryActions={[
          {
            content: "Manage Product Groups",
            icon: AddProductMajor,
            onClick: () => navigate("/settings/product_groups")
          },
        ]}
        pagination={{
          hasPrevious: products.contents.previous != null,
          hasNext: products.contents.next != null,
          onNext: () => {
            setProductsOffset(productsOffset + 7)
          },
          onPrevious: () => {
            var o = productsOffset - 7
            if (o < 0) {
              o = 0
            }
            setProductsOffset(o)
          },

        }}>

        {/* <Link onClick={() => navigate("/settings/product_groups")}> <Icon source={AddProductMajor} /> Manage Product Groups</Link> */}

        <Card>

          <ResourceList
            resourceName={{ singular: 'product', plural: 'products' }}
            filterControl={filterControl}
            items={products.state == 'hasValue' ? products.contents.results : []}
            loading={products.state == 'loading'}
            renderItem={(item) => {

              if (item == []) {
                return <div>Jeff </div>
              }
              const { id, image_url, name, star_rating, review_count } = item as any;
              const media = <img src={image_url} style={{ height: "80px", width: "80px", objectFit: "cover", borderRadius: "3px" }} />;

              return (

                <ResourceItem
                  id={id as unknown as string}
                  onClick={() => {
                    navigate("/products/" + id)
                  }}
                  media={media}
                  accessibilityLabel={`View details for ${name}`}
                  shortcutActions={[
                    {
                      content: 'Copy Manual Review link',
                      accessibilityLabel: `Copy Review link`,
                      onAction: () => {
                        aFetch(`/api/products/${id}/request/`, {
                          method: 'POST',
                          headers: {
                            "Content-Type": "application/json"
                          },
                        }).then(
                          response => {
                            return response.json()
                          }
                        ).then(response => {
                          copyToClipboard("https://" + window.location.hostname + "/r/" + response["key"])
                          setShowToast(true)
                        }).catch((e: any) => console.error(e))

                      },
                    },

                  ]}
                >


                  <Stack distribution="equalSpacing">
                    <div style={{ lineHeight: "85px", verticalAlign: "middle" }}>
                      <h3>
                        <TextStyle variation="strong">{name}</TextStyle>
                      </h3>
                    </div>

                    <div style={{ paddingTop: "32px", textAlign: "center" }}>
                      <StarsBadge count={star_rating} /> <div style={{ display: "inline-block", height: "25px", verticalAlign: "top" }}>({star_rating.toFixed(1)})</div><br />
                      {review_count} review{review_count == 1 ? "" : "s"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                  </Stack>

                </ResourceItem>

              );
            }}
          />
          {showToast && (
            <Toast content="Review Request Link Copied" onDismiss={() => setShowToast(false)} />
          )}

        </Card>



      </Page>

    </Frame>
  );

  function sortCurrency(rows: any, index: any, direction: any) {
    return [...rows].sort((rowA, rowB) => {
      const amountA = parseFloat(rowA[index].substring(1));
      const amountB = parseFloat(rowB[index].substring(1));

      return direction === 'descending' ? amountB - amountA : amountA - amountB;
    });
  }
}


interface AccountProps {
  onAction(): void;
}
