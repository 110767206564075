import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ConfettiExplosion from 'react-confetti-explosion';
import moment from 'moment';


import { Caption, DisplayText, DropZone, Heading, Icon, List, Modal, ProgressBar, Select, Spinner, Stack, TextStyle, Thumbnail } from "@shopify/polaris";
import { NoteMinor, PlusMinor } from "@shopify/polaris-icons";
import { CSSTransition } from 'react-transition-group';
import { CustomStar } from "./Products";
import { useIntercom } from "react-use-intercom";

import { aFetch } from './Index';

interface ReviewImportModalProps {
    modalOpen: boolean
    closeModal(): void

}

export function ReviewImportModal(props: ReviewImportModalProps) {

    const [importDisabled, setImportDisabled] = useState(false);

    const [importing, setImporting] = useState(false);

    const [files, setFiles] = useState([] as any[]);

    const [results, setResults] = useState({} as {
        success: number;
        duplicate: number;
        product_not_found: number;
        item_count: number;
        completed_count: number;
    })

    const [resultsProgress, setResultsProgress] = useState(0)

    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) => {
            setFiles((files) => [...acceptedFiles])
            console.dir(acceptedFiles)
        }

        ,
        [],
    );

    const importOptions = [
        { value: "detect", label: "Detect Import Type" },
        { value: "spr", label: "Shopify Product Reviews" },
        { value: "yotpo", label: "Yotpo" },
        { value: "yelp", label: "Yelp" },
        { value: "judgeme", label: "Judge.me" },
        { value: "stamped", label: "Stamped" },
    ];

    const [importType, setImportType] = useState("spr")

    const uploadedFiles = files.length > 0 && (
        <div style={{ padding: '0', top: '40%', textAlign: 'center', position: 'relative' }}>

            {files.map((file, index) => (



                <span key={index}>{file.name}</span>


            ))}

        </div>
    );

    const doImport = () => {
        var data = new FormData()
        data.append('csv', files[0])


        var intervalCount = 0

        setImporting(true)
        aFetch('/api/reviews_import/', {
            method: 'POST',
            body: data
        }).then((response) => {
            if (response.ok) {

                return response.json()
            }

        }).then((responseJSON) => {
            console.dir(responseJSON)
            var importStatusInterval = setInterval(() => {

                intervalCount = intervalCount + 1

                if (intervalCount > 2000) {
                    clearInterval(importStatusInterval)
                }
                aFetch('/api/reviews_import/' + responseJSON['task_id'] + "/", {
                    method: 'GET'
                }).then((response) => {
                    if (response.ok) {
                        return response.json()
                    }

                }).then((responseJSON) => {
                    console.log(responseJSON['status'])
                    if (responseJSON['status'] == "done") {

                        setResults(responseJSON['results'])
                        clearInterval(importStatusInterval)
                        setImporting(false)
                        setFiles([])
                    } else {
                        setResultsProgress((responseJSON.completed_count / responseJSON.item_count) * 100.0)
                    }
                })

            }, 1000)
        })



    }

    const closeAndResetModal = () => {
        props.closeModal()
        setImporting(false)
        setFiles([])
        setResultsProgress(0)
        setResults({} as {
            success: number;
            duplicate: number;
            product_not_found: number;
            item_count: number;
            completed_count: number;
        })
    }
    return (

        <Modal open={props.modalOpen}
            noScroll={true}
            title={"Import Reviews"}
            onClose={() => {
                closeAndResetModal()
            }}

            primaryAction={{
                content: resultsProgress == 0 ? 'Import' : 'Close',
                onAction: () => resultsProgress == 0 ? doImport() : closeAndResetModal(),
                disabled: files.length == 0 && resultsProgress == 0,
                loading: importing == true
            }}
            secondaryActions={resultsProgress == 0 && importing == false ? [{
                content: 'Close',
                onAction: () => closeAndResetModal(),
            }] : []}
        >




            {importing == false && resultsProgress == 0 && (
                <Modal.Section>
                    <Select
                        label="Select existing provider"
                        options={importOptions}
                        onChange={(val) => setImportType(val)}
                        value={importType}
                        helpText="If your provider isn't listed, contact us and we'll add it."
                    />
                    <br />
                    <DropZone allowMultiple={false} label="Upload CSV export containing existing reviews" accept=".csv" onDrop={handleDropZoneDrop}>

                        {uploadedFiles}
                        {files.length == 0 ? <DropZone.FileUpload /> : null}
                    </DropZone>
                </Modal.Section>)}
            {importing == true && Object.keys(results).length == 0 && (

                <Modal.Section>
                    <div style={{ textAlign: "center" }}>
                        <DisplayText size="small">Processing import...</DisplayText>;
                    </div>
                    <Stack distribution="center">


                        <div style={{width: 325}}>
                        <ProgressBar progress={resultsProgress} />
                        </div>

                    </Stack>
                </Modal.Section>

            )}

            {importing == false && Object.keys(results).length > 0 && (
                <Modal.Section>
                    Your import is complete<br/><br/>
                    <List type="bullet">
                        <List.Item>{results.success} product review{results.success == 1 ? "" : "s"} <TextStyle variation="positive">successfully imported.</TextStyle></List.Item>
                        {results.duplicate > 0 && (<List.Item>{results.duplicate} duplicate review{results.duplicate == 1 ? "" : "s"} <TextStyle variation="negative">skipped</TextStyle>.</List.Item>)}
                        {results.product_not_found > 0 && (<List.Item>{results.product_not_found} review{results.product_not_found == 1 ? "" : "s"} <TextStyle variation="negative">skipped</TextStyle> because the product wasn't found.</List.Item>)}
                    </List>
                </Modal.Section>
            )}


        </Modal>
    )

}
