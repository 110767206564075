import { useState, Fragment } from 'react';
import {
  Page,
  Card,
  Button,
  FormLayout,
  TextField,
  Form,
  DisplayText,
  TextContainer,
} from '@shopify/polaris';

interface AccountProps {
  onAction(): void;
}

export function Become() {

  const [storeName, setStoreName] = useState('');


  const handleSubmit = (e: any) => {
    e.preventDefault()
    alert("become")
    window.location.href = "/admin/do_become?shop=" + storeName
  }

  const handleStoreChange = (value: any) => {  setStoreName(value) }

  return (
    <Fragment >


      <Page
        narrowWidth

      >

        <br /><br />
        <div style={{ width: "100%", margin: "0 auto", textAlign: "center", paddingBottom: "2rem"}}>
        <img style={{ width: "60%" }} src="https://fulfilled-media.s3.amazonaws.com/logo/logo-large.png" />
        </div>
        <Card title={(<DisplayText size="large">Staffside Login 🔒</DisplayText>)} sectioned>





          <Form name="theForm" method="post" onSubmit={handleSubmit} action="/admin/do_become">
            <FormLayout>

              <TextField
                value={storeName}
                onChange={handleStoreChange}
                label=""
                type="text"
                autoComplete="off"
                autoFocus
                placeholder='Enter the Shopify Store URL'

              />

              <Button primary submit fullWidth>Log in</Button>


            </FormLayout>
          </Form>

        </Card>



      </Page>

    </Fragment>
  );
}
