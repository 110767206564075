import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import {
  RecoilRoot
} from 'recoil';

import '@shopify/polaris/build/esm/styles.css'
import { Overview } from './Overview';
import { License } from './License';
import { Review, Reviews } from './Reviews';
import { Products, Product, SuspendedAppFrame } from './Products';
import { Provider, useRoutePropagation } from '@shopify/app-bridge-react';
import { Settings } from './Settings';
import { Install } from './Install';
import { createApp } from '@shopify/app-bridge';
import { Login } from './Login';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { IntercomProvider } from 'react-use-intercom';
import { Become } from './Become';
import { SettingsProductGroup, SettingsProductGroupsList } from './SettingsProductGroups';
import { SettingsCapture } from './SettingsCapture';
import { SettingsReviewManagement } from './SettingsReviewManagement';
import { SettingsOnsite } from './SettingsOnsite';

const INTERCOM_APP_ID = 'cnjegw1q';
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
var app = null

var host = params.host
export var aFetch = fetch;

if (host) {

  app = createApp({
    apiKey: process.env.apiKey as string,
    host: host
  });

  aFetch = authenticatedFetch(app)
}

function MyRoutes(props: any) {

  const location = useLocation();

  useRoutePropagation(location);

  return (
    <>




      {(host != undefined && host.length > 0) && (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
          <Routes>
            <Route path="/license" element={<SuspendedAppFrame><License /></SuspendedAppFrame>} />
            <Route path="/dashboard" element={<SuspendedAppFrame><Overview /></SuspendedAppFrame>} />
            <Route path="/reviews" element={<SuspendedAppFrame><Reviews /></SuspendedAppFrame>} />
            <Route path="/reviews/:id" element={<SuspendedAppFrame><Review /></SuspendedAppFrame>} />
            <Route path="/products" element={<SuspendedAppFrame><Products /></SuspendedAppFrame>} />
            <Route path="/products/:id" element={<SuspendedAppFrame><Product /></SuspendedAppFrame>} />
            <Route path="/settings" element={<SuspendedAppFrame><Settings /> </SuspendedAppFrame>} />
            <Route path="/settings/capture" element={<SuspendedAppFrame><SettingsCapture /> </SuspendedAppFrame>} />
            <Route path="/settings/moderation" element={<SuspendedAppFrame><SettingsReviewManagement /> </SuspendedAppFrame>} />
            <Route path="/settings/onsite" element={<SuspendedAppFrame><SettingsOnsite /></SuspendedAppFrame>} />
            <Route path="/settings/product_groups" element={<SuspendedAppFrame><SettingsProductGroupsList /></SuspendedAppFrame>} />
            <Route path="/settings/product_groups/:id" element={<SuspendedAppFrame><SettingsProductGroup /></SuspendedAppFrame>} />
          </Routes>
        </IntercomProvider>
      )}
    </>
  );
}



ReactDOM.render(
  <AppProvider i18n={enTranslations}>
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/become" element={<Become />} />
          <Route path="/install" element={<Install />} />

          {(host == undefined) && (

            <Fragment>
              <Route path="/license" element={<SuspendedAppFrame><License /></SuspendedAppFrame>} />
              <Route path="/dashboard" element={<SuspendedAppFrame><Overview /></SuspendedAppFrame>} />
              <Route path="/reviews" element={<SuspendedAppFrame><Reviews /></SuspendedAppFrame>} />
              <Route path="/reviews/:id" element={<SuspendedAppFrame><Review /></SuspendedAppFrame>} />
              <Route path="/products" element={<SuspendedAppFrame><Products /></SuspendedAppFrame>} />
              <Route path="/products/:id" element={<SuspendedAppFrame><Product /></SuspendedAppFrame>} />
              <Route path="/settings" element={<SuspendedAppFrame><Settings /> </SuspendedAppFrame>} />
              <Route path="/settings/capture" element={<SuspendedAppFrame><SettingsCapture /> </SuspendedAppFrame>} />
              <Route path="/settings/moderation" element={<SuspendedAppFrame><SettingsReviewManagement /> </SuspendedAppFrame>} />
              <Route path="/settings/onsite" element={<SuspendedAppFrame><SettingsOnsite /></SuspendedAppFrame>} />
              <Route path="/settings/product_groups" element={<SuspendedAppFrame><SettingsProductGroupsList /></SuspendedAppFrame>} />
              <Route path="/settings/product_groups/:id" element={<SuspendedAppFrame><SettingsProductGroup /></SuspendedAppFrame>} />
            </Fragment>

        )}

        </Routes>
        {(host != undefined && host.length > 0) && (
          <Provider
            config={{
              apiKey: process.env.apiKey as string,
              host: host,
            }}
          >

            <MyRoutes />
          </Provider>


        )}
      </BrowserRouter>
    </RecoilRoot>
    </IntercomProvider>
  </AppProvider>,
  document.getElementById('root'),
);
