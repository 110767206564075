import { useState, useCallback, useEffect, Fragment } from 'react'
import { Layout, Page, Card, TextStyle, Filters, Badge, Tabs, ResourceList, ResourceItem, Avatar, Toast, OptionList, Stack, TextField, Frame } from '@shopify/polaris'
import { ArchiveMinor, StarOutlineMinor, StarFilledMinor, ArchiveMajor, ImportMinor } from '@shopify/polaris-icons'

import { ReviewsHistogram, StarsBadge } from './Products'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { ResourceListSelectedItems } from '@shopify/polaris/build/ts/latest/src/utilities/resource-list'
import { atom, atomFamily, selector, selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { aFetch } from './Index'
import { ReviewImportModal } from './ReviewImportModal'

const reviewsQueryState = atom({
    key: 'reviewsQuery',
    default: '',
})

const reviewsRatingState = atom({
    key: 'reviewsRating',
    default: ['any'],
})

const reviewsSortState = atom({
    key: 'reviewsSort',
    default: 'DATE_MODIFIED_DESC',
})

const reviewsMediaState = atom({
    key: 'reviewsMedia',
    default: ['any'],
})

const reviewsOffsetState = atom({
    key: 'reviewsOffset',
    default: 0,
})

const reviewsStatusState = atom({
    key: 'reviewsStatus',
    default: 'All',
})

const currentReviewAtom = atomFamily({
    key: 'CurrentReview',
    effects: [
        ({ onSet }) => {
            onSet((review: any) => {
                aFetch(`/api/reviews/${review['id']}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(review),
                })
            })
        },
    ],
    default: selectorFamily({
        key: 'CurrentReview/Default',
        get:
            (id: number) =>
            ({ get }) => {
                return aFetch(`/api/reviews/${id}`).then((response) => {
                    console.log('Got response')
                    return response.json()
                })
            },
    }),
})

const reviewsQuery = selectorFamily({
    key: 'Reviews',
    get:
        (offset: number) =>
        async ({ get }) => {
            console.log('Getting')
            return aFetch(
                `/api/reviews/?limit=30&offset=${String(offset)}&filter=${get(reviewsQueryState)}&status=${get(reviewsStatusState)}&rating=${get(reviewsRatingState)}&media=${get(
                    reviewsMediaState
                )}&sort=${get(reviewsSortState)}`
            ).then((response) => {
                console.log('Got response')
                return response.json()
            })
        },
})

export function Reviews() {
    const [reviewsOffset, setReviewsOffset] = useRecoilState(reviewsOffsetState)

    const reviews = useRecoilValueLoadable(reviewsQuery(reviewsOffset))
    const [reviewsQueryString, setReviewsQueryString] = useRecoilState(reviewsQueryState)

    const [reviewStatusFilter, setReviewStatusFilter] = useRecoilState(reviewsStatusState)

    const [sortValue, setSortValue] = useRecoilState(reviewsSortState)

    const [ratingsFilter, setRatingsFilter] = useRecoilState(reviewsRatingState)
    const [mediaFilter, setMediaFilter] = useRecoilState(reviewsMediaState)

    const [productsFilter, setProductsFilter] = useState([] as string[])
    const [selectedTab, setSelectedTab] = useState(0)
    const [appliedFilters, setAppliedFilters] = useState([] as any[])
    const [selectedItems, setSelectedItems] = useState([] as ResourceListSelectedItems)
    const [pendingCount, setPendingCount] = useState(0)

    const [importModalActive, setImportModalActive] = useState(false)

    let navigate = useNavigate()

    const tabs = [
        {
            id: 'all_reviews',
            content: 'All',
            accessibilityLabel: 'All Reviews',
        },
        {
          id: 'questions',
          content: 'Questions',
          accessibilityLabel: 'Questions',
      },
        {
            id: 'pending_reviews',
            content: pendingCount > 0 ? `Pending (${pendingCount})` : 'Pending',
            accessibilityLabel: 'Pending Reviews',
        },
        {
            id: 'featured_reviews',
            content: 'Featured',
            accessibilityLabel: 'Featured',
        },
        {
            id: 'published_reviews',
            content: 'Published',
            accessibilityLabel: 'Published Reviews',
        },
        {
            id: 'archived_reviews',
            content: 'Archived',
            accessibilityLabel: 'Archived Reviews',
        },
    ]

    const ratingsFilterOptions = [
        { label: '5', value: '5' },
        { label: '4', value: '4' },
        { label: '3', value: '3' },
        { label: '2', value: '2' },
        { label: '1', value: '1' },
    ]

    function disambiguateLabel(key: any, value: any) {
        switch (key) {
            case 'ratingFilter':
                return `Rated ${value} stars`
            case 'mediaFilter':
                return `Includes ${value[0].toUpperCase() + value.slice(1)}`
            default:
                return value
        }
    }

    const filters = [
        {
            key: 'rating',
            label: 'Rating',
            filter: (
                <OptionList
                    onChange={(value) => setRatingsFilter(value)}
                    allowMultiple={false}
                    options={[
                        { value: 'any', label: 'All' },
                        { value: '5', label: <StarsBadge count={5} /> },
                        { value: '4', label: <StarsBadge count={4} /> },
                        { value: '3', label: <StarsBadge count={3} /> },
                        { value: '2', label: <StarsBadge count={2} /> },
                        { value: '1', label: <StarsBadge count={1} /> },
                    ]}
                    selected={ratingsFilter}
                />
            ),
            hideClearButton: true,
            shortcut: true,
        },
        {
            key: 'media',
            label: 'Media',
            hideClearButton: true,
            filter: (
                <OptionList
                    onChange={(value) => setMediaFilter(value)}
                    allowMultiple={false}
                    options={[
                        { value: 'any', label: 'All' },
                        { value: 'photos', label: 'Includes Photos' },
                        { value: 'videos', label: 'Includes Videos' },
                        { value: 'both', label: 'Includes Photos & Videos' },
                    ]}
                    selected={mediaFilter}
                />
            ),
            shortcut: true,
        },
    ]

    const resourceName = {
        singular: 'review',
        plural: 'reviews',
    }

    function renderItem(item: any) {
        const { id, author } = item

        return (
            <ResourceItem
                id={id}
                onClick={() => {
                    navigate(`/reviews/${id}`)
                }}
                accessibilityLabel={`View details for ${author}`}
                persistActions
            >
                <ReviewContent item={item} />
            </ResourceItem>
        )
    }

    const promotedBulkActions = [
        {
            content: 'Publish',
            onAction: () => console.log('Todo: implement bulk edit'),
        },
        {
            content: 'Archive',
            onAction: () => console.log('Todo: implement bulk edit'),
        },
    ]

    const bulkActions = [
        {
            content: 'Add tags',
            onAction: () => console.log('Todo: implement bulk add tags'),
        },
        {
            content: 'Remove tags',
            onAction: () => console.log('Todo: implement bulk remove tags'),
        },
        {
            content: 'Delete customers',
            onAction: () => console.log('Todo: implement bulk delete'),
        },
    ]

    const filterControl = (
        <Filters
            queryValue={reviewsQueryString}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={(value) => {
                setReviewsQueryString(value)
            }}
            onQueryClear={() => {
                setReviewsQueryString('')
            }}
            onClearAll={() => {}}
        ></Filters>
    )

    const sortReviews = (reviews: any) => {
        var r = [...reviews]
        if (sortValue == 'RATING_ASC') {
            r.sort((a, b) => {
                return a.rating - b.rating
            })
        } else if (sortValue == 'RATING_DESC') {
            r.sort((a, b) => {
                return b.rating - a.rating
            })
        } else if (sortValue == 'DATE_MODIFIED_ASC') {
            r.sort((a, b) => {
                return a.id - b.id
            })
        } else if (sortValue == 'DATE_MODIFIED_DESC') {
            r.sort((a, b) => {
                return b.id - a.id
            })
        }

        return r
    }

    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelectedTab(selectedTabIndex)
        setReviewStatusFilter(tabs[selectedTabIndex].content)
    }, [])

    return (
        <Frame>
            <Page
                title="Manage Reviews and Questions"
                divider={false}
                pagination={{
                    hasPrevious: reviews.contents.previous != null,
                    hasNext: reviews.contents.next != null,
                    onNext: () => {
                        setReviewsOffset(reviewsOffset + 30)
                    },
                    onPrevious: () => {
                        var o = reviewsOffset - 30
                        if (o < 0) {
                            o = 0
                        }
                        setReviewsOffset(o)
                    },
                }}
                secondaryActions={[
                    {
                        content: 'Import',
                        icon: ImportMinor,
                        onAction: () => setImportModalActive(true),
                    },

                ]}
            >
                <Card>
                    <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
                        <ResourceList
                            filterControl={filterControl}
                            resourceName={resourceName}
                            items={reviews.state == 'hasValue' ? reviews.contents.results : []}
                            loading={reviews.state == 'loading'}
                            renderItem={renderItem}
                            selectedItems={selectedItems}
                            onSelectionChange={setSelectedItems}
                            sortValue={sortValue}
                            // promotedBulkActions={promotedBulkActions}
                            // bulkActions={bulkActions}
                            sortOptions={[
                                { label: 'Most Recent', value: 'DATE_MODIFIED_DESC' },
                                { label: 'Oldest', value: 'DATE_MODIFIED_ASC' },
                                { label: 'Highest Rated', value: 'RATING_DESC' },
                                { label: 'Lowest Rating', value: 'RATING_ASC' },
                            ]}
                            resolveItemId={(id) => {
                                return id
                            }}
                            onSortChange={(selected) => {
                                setSortValue(selected)
                            }}
                        />
                    </Tabs>
                </Card>
                <ReviewImportModal modalOpen={importModalActive} closeModal={() => setImportModalActive(false)} />
            </Page>
        </Frame>
    )
}

function ReviewContent(props: any) {
    const { rating, content, author, title, image_uuid, status, updated_at, product, public_reply_content, custom_form_json, type} = props.item

    return (
        <div style={{ position: 'relative', minHeight: '120px' }}>
            <Stack vertical={false} wrap={false}>
                <Stack.Item fill>

                    {type == "question" && (<Badge progress={public_reply_content != '' && public_reply_content != null ? "complete" : "incomplete"} status="attention">Customer Question</Badge>)}
                    {rating > 0 && <StarsBadge count={rating} />}
                    {custom_form_json != null && custom_form_json != '' && (
                        <Fragment>
                            <br />
                            {Object.entries(JSON.parse(custom_form_json)).map(([question, answer]) => {
                                return (
                                    <div key={question}>
                                        <strong style={{fontSize: "0.8em"}}>{question && question.split('__').length > 1 ? question.split('__')[1] : question.split('__')[0]}:</strong>
                                        &nbsp;
                                        <span>{answer as string}</span>
                                    </div>
                                )
                            })}
                            <br />
                        </Fragment>
                    )}

                    {title != null && title.length > 4 && <h3 style={{ fontWeight: 600 }}>{title}</h3>}

                    <p>{content}</p>
                    <p>
                        {image_uuid &&
                            image_uuid != '' &&
                            image_uuid.split(',').map((image_url: string) => {
                                return (
                                    <img
                                        key={image_url}
                                        src={`https://reviews-media.services.klaviyo.com/abc/width:200/height:200/plain/https:\/\/fulfilled-app-dev.s3.amazonaws.com/hemancuso.myshopify.com/${image_url}@png`}
                                        style={{ borderRadius: '10px', width: '100px', height: 'auto', display: 'inline-block' }}
                                    />
                                )
                            })}
                    </p>
                </Stack.Item>
                <Stack.Item>
                    <div style={{ maxWidth: '150px', textAlign: 'right', height: '100%' }}>
                        {author}
                        <br />
                        {moment(updated_at).format('MMMM Do YYYY')}
                        <br />
                        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                            {public_reply_content != '' && public_reply_content != null && (
                                <Fragment>
                                    <Badge status={'info'}>Reply Published</Badge>&nbsp;
                                </Fragment>
                            )}
                            <Badge status={status == 'Published' || status == 'Featured' ? 'success' : 'info'}>{status}</Badge>
                        </div>
                    </div>
                </Stack.Item>
            </Stack>
        </div>
    )
}

var fetchReview = (id: string) =>
    aFetch(`/api/reviews/${id?.toString()}/`)
        .then((response) => {
            return response.json()
        })
        .then((rj) => {
            return rj[0]
        })

export function Review(props: any) {
    let { id } = useParams()

    const refreshReviews = useRecoilRefresher_UNSTABLE(reviewsQuery(0))

    const [publicReply, setPublicReply] = useState('')

    const [activeToast, setActiveToast] = useState(false)
    const toggleActiveToast = useCallback(() => setActiveToast((activeToast) => !activeToast), [])

    let navigate = useNavigate()

    const [currentReview, setCurrentReview] = useRecoilState<any>(currentReviewAtom(Number(id)))

    useEffect(() => {

       setPublicReply(currentReview["public_reply_content"])

      }, [currentReview])


    return (
        <Frame>
            <Page
                secondaryActions={[
                    {
                        content: currentReview['status'] != 'Featured' ? 'Feature' : 'Unfeature',
                        icon: currentReview['status'] == 'Featured' ? StarFilledMinor : StarOutlineMinor,
                        id: currentReview['status'] == 'Featured' ? 'featured-toggle-on' : 'featured-toggle-off',
                        onAction: () => {
                            var r = Object.assign({}, currentReview)
                            r.status = currentReview['status'] != 'Featured' ? 'Featured' : 'Published'
                            setCurrentReview(r)
                            refreshReviews()
                        },
                    },
                    {
                        content: currentReview['status'] != 'Archived' ? 'Archive' : 'Unarchive',
                        icon: currentReview['status'] == 'Archived' ? ArchiveMinor : ArchiveMajor,
                        onAction: () => {
                            var r = Object.assign({}, currentReview)
                            r.status = currentReview['status'] != 'Archived' ? 'Archived' : 'Pending'

                            setCurrentReview(r)
                            refreshReviews()
                        },
                    },
                ]}
                breadcrumbs={[
                    {
                        onAction: () => {
                            navigate('/reviews')
                        },
                    },
                ]}
                primaryAction={{
                    content: currentReview['status'] == 'Published' || currentReview['status'] == 'Featured' ? 'Unpublish' : 'Publish',
                    destructive: currentReview['status'] == 'Published' ? true : false,
                    onAction: () => {
                        var r = Object.assign({}, currentReview)
                        r.status = currentReview['status'] == 'Published' || currentReview['status'] == 'Featured' ? 'Unpublished' : 'Published'
                        setCurrentReview(r)
                        refreshReviews()
                    },
                }}
                titleMetadata={
                    <Badge status={currentReview['status'] == 'Published' || currentReview['status'] == 'Featured' ? 'success' : 'info'}>{currentReview['status']}</Badge>
                }
                title={currentReview['title']}
                subtitle={moment(currentReview['updated_at']).format('MMMM Do YYYY [at] h:mm:ss a')}
                divider={false}
            >
                {/* <TitleBar title="Review Detail" /> */}
                <Layout>
                    <Layout.Section>
                        {activeToast ? <Toast content="Reply Published" duration={4000} onDismiss={toggleActiveToast} /> : null}

                        <Card
                            secondaryFooterActions={[
                                {
                                    content: 'Save Reply',
                                    onAction: () => {
                                        setActiveToast(true)
                                        var newReview = Object.assign({}, currentReview)
                                        newReview['public_reply_content'] = publicReply
                                        setCurrentReview(newReview)

                                    },
                                },
                            ]}
                        >
                            <Card.Section>
                                <ReviewContent item={currentReview} />
                            </Card.Section>
                            <Card.Section>
                                <TextField
                                    label=""
                                    placeholder="Add a public reply..."
                                    value={publicReply}
                                    onChange={(newValue) => {
                                        setPublicReply(newValue)
                                    }}
                                    multiline={4}
                                    autoComplete="off"
                                />
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card title="Review Author" sectioned>
                            <div style={{ display: 'inline-block' }}>
                                <Avatar name={currentReview['author']} initials={currentReview['author'] ? currentReview['author'][0] : ''} />
                            </div>
                            <div style={{ display: 'inline-block', paddingLeft: '2em' }}>
                                <h3>{currentReview['author']}</h3>
                                <h3>{currentReview['email']}</h3>
                            </div>
                        </Card>
                        <Card title="Product" sectioned>
                            <div style={{ overflow: 'hidden' }}>
                                <div style={{ float: 'left', width: '150px' }}>
                                    {currentReview['product'] &&
                                        currentReview['product']['image_url'].split(',').map((image_url: string) => {
                                            return <img src={image_url} style={{ width: '140px', height: '140px', objectFit: 'cover', borderRadius: '10px' }} />
                                        })}
                                </div>
                                <div>
                                    {currentReview['product'] && (
                                        <Fragment>
                                            <h2 style={{ fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>{currentReview['product']['name']}</h2>
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <h2 style={{ fontSize: '46px', fontWeight: '800', color: '#555', padding: '38px', paddingRight: '0px' }}>
                                                    {currentReview['product']['star_rating']}
                                                </h2>

                                                <StarsBadge count={currentReview['product']['star_rating']} />
                                                <br />
                                                <br />
                                            </div>
                                            <ReviewsHistogram {...currentReview['product']} />
                                            <span style={{ marginLeft: '110px' }}>{currentReview['product']['review_count']} ratings</span>
                                        </Fragment>
                                    )}
                                    <h3></h3>
                                </div>
                            </div>
                            <TextStyle variation="strong">Customers mentioned</TextStyle>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Frame>
    )
}

interface AccountProps {
    onAction(): void
}
