import {
  Layout,
  Page,
  Card,
  ResourceList,
  ResourceItem,
  TextStyle,
  Icon} from '@shopify/polaris';
import { CollectionsMajor, FavoriteMajor, SendMajor, StoreMajor } from '@shopify/polaris-icons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { legalAgreedAtAtom } from './License';




export function Settings() {

  let navigate = useNavigate();

  const [legalAgreedAt, setLegalAgreedAt] = useRecoilState(legalAgreedAtAtom);


  useEffect(() => {

    if (legalAgreedAt == "false") {
      navigate("/license")
    }

  }, [])

  return (

    <Page title='Settings'>
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              resourceName={{ singular: 'customer', plural: 'customers' }}
              items={[
                {
                  id: 100,
                  url: '/settings/capture',
                  name: 'Review Collection',
                  location: 'Improve how you collect your reviews.',
                  icon: SendMajor
                },
                {
                  id: 200,
                  url: '/settings/moderation',
                  name: 'Review Management',
                  location: 'Control the reviews you have and import existing reviews.',
                  icon: FavoriteMajor
                },
                {
                  id: 300,
                  url: '/settings/onsite',
                  name: 'Onsite Widgets',
                  location: 'Make a beautiful integration with your website.',
                  icon: StoreMajor
                },
                {
                  id: 400,
                  url: '/settings/product_groups',
                  name: 'Product Groups',
                  location: 'Share reviews amongst similar products.',
                  icon: CollectionsMajor
                }
              ]}
              renderItem={(item) => {
                const { id, url, name, location, icon } = item;
                const media = <div className='LargeIcon'><Icon source={icon} /></div>;

                return (
                  <ResourceItem
                    id={id as unknown as string}

                    onClick={() => {
                      navigate(url)

                    }}
                    media={media}
                    accessibilityLabel={`View details for ${name}`}
                  >
                    <div style={{ padding: "1em" }}>
                      <h3>
                        <TextStyle variation="strong">{name}</TextStyle>
                      </h3>
                      <div>{location}</div>
                    </div>
                  </ResourceItem>
                );
              }}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>

  )
}
