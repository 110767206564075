import { useState, useCallback, useEffect, Fragment } from 'react';
import {
  Layout,
  Page,
  Card,
  Frame,
  Button,
  Stack,
  TextField,
  ResourceList,
  ResourceItem,
  Modal,
  TextStyle,
  Filters,
  Scrollable,
  Checkbox,
  Pagination
} from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import { aFetch } from './Index';
import { AddProductMajor } from '@shopify/polaris-icons';
import { StarsBadge } from './Products';
import { atom, atomFamily, selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { group } from 'console';



export const productsInGroupOffsetState = atom({
  key: 'productsInGroupOffsetState',
  default: 0,
});

export const productsInGroupQueryState = atom({
  key: 'productsInGroupQueryState',
  default: "",
});


export const allProductsQueryState = atom({
  key: 'allProductsQueryState',
  default: "",
});

export const allProductsOffsetState = atom({
  key: 'allProductsOffsetState',
  default: 0,
});

const allProductsQuery = selectorFamily({
  key: 'allProductsQuery',
  get: (limit: number) => async ({ get }) => {
    return aFetch(`/api/products/?limit=${String(limit)}&offset=0&filter=${get(allProductsQueryState)}`).then(
      response => {
        return response.json()
      }
    )


  },
});

const productsInGroupQuery = selectorFamily({
  key: 'ProductsInGroup',
  get: (product_group: number) => async ({ get }) => {
    return aFetch(`/api/products/?limit=7&offset=${get(productsInGroupOffsetState)}&product_group=${String(product_group)}&filter=${get(productsInGroupQueryState)}`).then(
      response => {
        return response.json()
      }
    )
  },
});

const productGroupQuery = selectorFamily({
  key: 'Productroup',
  get: (product_group: number) => async ({ get }) => {
    return aFetch(`/api/product_groups/${String(product_group)}/`).then(
      response => {
        return response.json()
      }
    )
  },
});




export function SettingsProductGroup() {

  let navigate = useNavigate();

  let { id } = useParams();

  const [productsOffset, setProductsOffset] = useRecoilState(productsInGroupOffsetState);

  const [allProductsLimit, setAllProductsLimit] = useState(7);


  const productsInGroup = useRecoilValueLoadable(productsInGroupQuery(Number(id)));

  const refreshProductsInGroup = useRecoilRefresher_UNSTABLE(productsInGroupQuery(Number(id)));

  const allProducts = useRecoilValueLoadable(allProductsQuery(allProductsLimit));

  const refreshAllProducts = useRecoilRefresher_UNSTABLE(allProductsQuery(allProductsLimit));

  const productGroup = useRecoilValueLoadable(productGroupQuery(Number(id)));



  const refreshProductGroup = useRecoilRefresher_UNSTABLE(productGroupQuery(Number(id)));

  const [modalActive, setModalActive] = useState(false);

  const [editModalActive, setEditModalActive] = useState(false);

  const [modalToAdd, setModalToAdd] = useState<Number[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);


  const [productsInGroupQueryString, setProductsInGroupQueryString] = useRecoilState(productsInGroupQueryState);
  const [allProductsQueryString, setAllProductsQueryString] = useRecoilState(allProductsQueryState);

  const filterControl = (
    <Filters
      queryValue={productsInGroupQueryString}
      onQueryChange={(value) => {
        setProductsInGroupQueryString(value);
      }}

      onQueryClear={() => { setProductsInGroupQueryString(""); }}
      onClearAll={() => { }} filters={[]}    >

    </Filters>
  );


  const modalFilterControl = (
    <Filters
      queryValue={allProductsQueryString}
      onQueryChange={(value) => {
        setAllProductsQueryString(value);
      }}

      onQueryClear={() => { setAllProductsQueryString(""); }}
      onClearAll={() => { }} filters={[]}    >

      <div style={{ paddingLeft: "8px" }}>
        <Pagination></Pagination>
      </div>

    </Filters>
  );





  const promotedBulkActions = [
    {
      content: `Remove Product${selectedItems.length > 1 ? "s" : ""}`,
      onAction: () => saveRemoveProducts(),
    },
  ];

  const saveAddProducts = () => {

    const fetchOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        new_products: modalToAdd
      }),
    };


    return aFetch(`/api/product_groups/${String(id)}/`, fetchOptions).then(

      response => {
        return response.json()
      }
    ).then(() => {

      setModalToAdd([])
      refreshProductsInGroup()
      refreshAllProducts()
      refreshProductGroup()
      setModalActive(false)
    })
  }


  const saveRemoveProducts = () => {

    const fetchOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        remove_products: selectedItems
      }),
    };


    return aFetch(`/api/product_groups/${String(id)}/`, fetchOptions).then(

      response => {
        return response.json()
      }
    ).then(() => {
      refreshProductsInGroup()
      refreshAllProducts()
      refreshProductGroup()
      setSelectedItems([])

    })
  }

  return (
    <Frame>
      <Page
        breadcrumbs={[{ onAction: () => { navigate("/settings/product_groups") } }]}
        title={productGroup.contents.name}
        subtitle={`${productGroup.contents.review_count ? (productGroup.contents.review_count + " shared reviews, " + productGroup.contents.star_rating + " stars average") : ""} `}

        pagination={{
          hasPrevious: productsInGroup.contents.previous != null,
          hasNext: productsInGroup.contents.next != null,
          onNext: () => {
            setProductsOffset(productsOffset + 7)
          },
          onPrevious: () => {
            var o = productsOffset - 7
            if (o < 0) {
              o = 0
            }
            setProductsOffset(o)
          }
        }}

        primaryAction={
          <Button
            primary
            icon={AddProductMajor}
            onClick={() => setModalActive(true)}
          >
            Add Products
          </Button>
        }
        secondaryActions={[{
          content: <Button
            plain
            onClick={() => setEditModalActive(true)}
          >
            Edit Group
          </Button>
        }]}
      >
        <Layout>

          <Layout.Section>

            <Card>
              <GroupModal id={id as string} modalActive={editModalActive} mode="edit" setModalActive={(v: boolean) => {
                setEditModalActive(v)
                refreshProductGroup()
              }} group={productGroup.contents} />

              <Modal
                open={modalActive}
                onClose={() => setModalActive(false)}
                // onScrolledToBottom={() => {

                //   setAllProductsLimit(allProductsLimit + 7)
                // }}
                title="Add Products"

                primaryAction={{
                  content: 'Add',
                  onAction: () => {
                    saveAddProducts()
                  }
                }}
                secondaryActions={[{
                  content: 'Close',
                  onAction: () => {
                    setModalToAdd([])
                    setModalActive(false)
                  },
                }]}

                footer=
                {modalToAdd.length > 0 &&
                  <div>{modalToAdd.length} product{modalToAdd.length > 1 ? "s" : ""} selected</div>
                }


              >
                <Modal.Section>
                  {modalFilterControl}

                </Modal.Section>
                <Modal.Section flush={true} >
                  <Scrollable style={{ height: "300px" }}>
                    <ResourceList

                      resourceName={{ singular: 'product', plural: 'products' }}

                      items={allProducts.state == 'hasValue' ? allProducts.contents.results : []}
                      loading={allProducts.state == 'loading' && allProductsLimit == 7}

                      renderItem={(item) => {

                        const { id, image_url, name, star_rating, review_count } = item as any;
                        const media = <img src={image_url} style={{ height: "40px", width: "40px", verticalAlign: "middle", objectFit: "cover", borderRadius: "3px" }} />;

                        return (

                          <ResourceItem
                            id={id as unknown as string}

                            onClick={() => {

                            }}

                            accessibilityLabel={`View details for ${name}`}
                          >


                            <Stack wrap={false}>

                              <Stack.Item >

                                <div style={{ marginTop: "6px" }}>
                                  <Checkbox
                                    label=""
                                    checked={productGroup.contents.products.includes(id) || modalToAdd.includes(id)}
                                    disabled={productGroup.contents.products.includes(id)}

                                    onChange={(v) => {

                                      var newIDs: Number[] = [...modalToAdd]

                                      if (v == true) {
                                        newIDs.push(Number(id))
                                      } else {
                                        newIDs = newIDs.filter((item) => {
                                          return item == Number(id)
                                        })
                                      }

                                      setModalToAdd(newIDs)

                                    }}
                                  />
                                </div>


                              </Stack.Item>
                              <Stack.Item>
                                {media}
                              </Stack.Item>
                              <Stack.Item fill>
                                {!productGroup.contents.products.includes(id) && (
                                  <div style={{ lineHeight: "2.5rem", verticalAlign: "middle", overflow: "hide", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>

                                    <TextStyle variation="strong">{name}</TextStyle>
                                  </div>)}


                                {productGroup.contents.products.includes(id) && (
                                  <Fragment>
                                    <TextStyle variation="strong">{name}</TextStyle><br />
                                    <TextStyle variation="warning">Product already in this group</TextStyle>
                                  </Fragment>
                                )}



                              </Stack.Item>



                            </Stack>

                          </ResourceItem>

                        );
                      }}
                    />
                  </Scrollable>


                </Modal.Section>



              </Modal>

              <ResourceList
                resourceName={{ singular: 'product', plural: 'products' }}
                filterControl={filterControl}
                items={productsInGroup.state == 'hasValue' ? productsInGroup.contents.results : []}
                loading={productsInGroup.state == 'loading'}
                selectedItems={selectedItems}
                onSelectionChange={(v: any[]) => setSelectedItems(v)}

                promotedBulkActions={promotedBulkActions}


                renderItem={(item) => {

                  if (item == []) {
                    return <div>Jeff </div>
                  }
                  const { id, image_url, name, star_rating, review_count } = item as any;
                  const media = <img src={image_url} style={{ height: "80px", width: "80px", objectFit: "cover", borderRadius: "3px" }} />;

                  return (

                    <ResourceItem
                      id={id as unknown as string}
                      onClick={() => {
                        navigate("/products/" + id)
                      }}
                      media={media}
                      accessibilityLabel={`View details for ${name}`}
                    >


                      <Stack distribution="equalSpacing">


                        <TextStyle variation="strong">{name}</TextStyle>


                        <div style={{ paddingTop: "32px", textAlign: "center" }}>
                          <StarsBadge count={star_rating} /> <div style={{ display: "inline-block", height: "25px", verticalAlign: "top" }}>({star_rating.toFixed(1)})</div><br />
                          {review_count} review{review_count == 1 ? "" : "s"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>

                      </Stack>

                    </ResourceItem>


                  );
                }}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>

  );
}

type GroupModalProps = {
  group: any;
  modalActive: any;
  setModalActive: any;
  mode: "create" | "edit"
  id: string;
};

function GroupModal(props: GroupModalProps) {

  const { group, modalActive, setModalActive, mode, id } = props

  const [groupName, setGroupName] = useState("");


  useEffect(() => {
    setGroupName(group.name)
  }, [group])

  const saveGroup = () => {




    const fetchOptions = {
      method: id == "new" ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: groupName,
      }),
    };


    return aFetch(`/api/product_groups/${id == "new" ? "" : id + "/"}`, fetchOptions).then(
      response => {
        return response.json()
      }
    ).then((r) => {
      setModalActive(false)
    })
  }


  return (<Modal
    open={modalActive}
    onClose={() => setModalActive(false)}

    title={(<div style={{ textTransform: 'capitalize' }}>{mode} Product Group</div>)}

    primaryAction={{
      content: mode == "create" ? "Create" : "Save",
      onAction: () => saveGroup(),
    }}
    secondaryActions={[{
      content: 'Close',
      onAction: () => setModalActive(false),
    }]}
  >
    <Modal.Section>
      <TextField
        value={groupName}
        label="Product Group Name"
        autoFocus
        placeholder='Enter a name'
        onChange={useCallback((v) => { setGroupName(v) }, [])}
        autoComplete="off"
      />
    </Modal.Section>

  </Modal>)
}

export function SettingsProductGroupsList() {




  let navigate = useNavigate();

  useEffect(() => {

    refreshGroups()

  }, [])


  const refreshGroups = () => {

    aFetch(`/api/product_groups/`).then(

      response => {

        return response.json()
      }
    ).then(response => {

      setGroups(response)
    }).catch((e: any) => console.error(e));



  }

  const [groups, setGroups] = useState([]);


  const [unsavedChanged, setUnsavedChanges] = useState(false);
  const [modalActive, setModalActive] = useState(false);




  return (


    <Frame>



      <Page
        breadcrumbs={[{ onAction: () => { navigate("/settings") } }]}
        title="Product Groups List"
        subtitle='Product groups allow multiple products with similar traits to share their reviews.'
        primaryAction={
          <Button
            primary
            icon={AddProductMajor}
            onClick={() => setModalActive(true)}
          >
            Add Product Group
          </Button>
        }
      >
        <GroupModal id="new" modalActive={modalActive} setModalActive={(v: boolean) => {
          setModalActive(v)
          refreshGroups()
          }} mode="create" group={{ name: "" }} />

        <Card>
          <ResourceList
            resourceName={{ singular: 'product group', plural: 'product groups' }}
            items={groups}

            renderItem={(item) => {
              const { id, name, products, star_rating, product_images } = item as any;
              return (
                <ResourceItem
                  id={id as unknown as string}
                  onClick={() => {
                    navigate("/settings/product_groups/" + id)
                  }}>
                  <Stack>
                    <Stack.Item>

                    <TextStyle variation="strong">{name}</TextStyle><br/>
                    <StarsBadge count={star_rating} /><br/>
                    {product_images.map((image_url:string) => {
                      return <img src={image_url} style={{ height: "70px", width: "70px", marginRight: "1em", objectFit: "cover", borderRadius: "3px" }} />
                    })}
                    </Stack.Item>

                    <Stack.Item fill>
                      <div style={{float: "right"}}>{products.length} products</div>
                    </Stack.Item>


                  </Stack>
                </ResourceItem>
              )

            }}
          />
        </Card>

      </Page>
    </Frame>

  );
}
