import { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Page,
  Card,
  FormLayout,
  TextField,
  ChoiceList,
  Toast,
  Frame,
  Stack,
  Select,
  Label,
  Button,
  Link
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { aFetch } from './Index';



export function SettingsCapture() {


  const [activeToast, setActiveToast] = useState(false);
  const [autoPublish, setAutoPublish] = useState(false);

  const toggleActiveToast = useCallback(() => setActiveToast((activeToast) => !activeToast), []);

  const [klaviyo_token, setKlaviyoToken] = useState('');
  const [klaviyo_secret, setKlaviyoSecret] = useState('');


  const [review_keyword_1, setReviewKeyword1] = useState('');
  const [review_keyword_2, setReviewKeyword2] = useState('');
  const [review_keyword_3, setReviewKeyword3] = useState('');
  const [review_keyword_4, setReviewKeyword4] = useState('');
  const [review_keyword_5, setReviewKeyword5] = useState('');
  const [review_keyword_6, setReviewKeyword6] = useState('');

  const [reviewsPriority, setReviewsPriority] = useState(['review_count']);
  const handleReviewsPriorityChange = useCallback((value) => setReviewsPriority(value), []);








  const handleKlaviyoTokenChange = useCallback((value) => setKlaviyoToken(value), []);
  const handleKlaviyoSecretChange = useCallback((value) => setKlaviyoSecret(value), []);


  const [buttonColorPicker, setButtonColorPicker] = useState(false);




  let navigate = useNavigate();




  const [selected, setSelected] = useState(['review_ciount']);


  useEffect(() => {

    console.log("getting session token")

    aFetch(`/api/shop/`).then(

      response => {

        return response.json()
      }
    ).then(response => {

      setAutoPublish(response["auto_publish"])

      setKlaviyoToken(response["klaviyo_token"])
      if (response["klaviyo_token"] != "") {
        setKlaviyoSecret("passwordpasswordpasswordpassword")
      }

      setReviewKeyword1(response["review_keyword_1"])
      setReviewKeyword2(response["review_keyword_2"])
      setReviewKeyword3(response["review_keyword_3"])
      setReviewKeyword4(response["review_keyword_4"])
      setReviewKeyword5(response["review_keyword_5"])
      setReviewKeyword6(response["review_keyword_6"])
      setReviewsPriority(response["reviews_priority"])
      setRequestDelayDaysShipped(response["request_delay_days_shipped"])
      setRequestDelayDaysPOS(response["request_delay_days_pos"])
    }).catch((e: any) => console.error(e));



  }, [])


  const [requestDelayDaysShipped, setRequestDelayDaysShipped] = useState(7);

  const [requestDelayDaysPOS, setRequestDelayDaysPOS] = useState(3);

  const handleSelectChangeDelay = useCallback((value) => setRequestDelayDaysShipped(value), []);

  const handleSelectChangeDelayPOS = useCallback((value) => setRequestDelayDaysPOS(value), []);



  const handleInstallScriptTag = () => {
    // aFetch(`/shopify/install`).then(
    //   response => {
    //     return response.json()
    //   }
    // ).then(response => {
    //   alert(JSON.stringify(response))
    // }).catch((e: any) => console.error(e));


  };





  const saveShop = () => {

    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        klaviyo_token: klaviyo_token,
        auto_publish: autoPublish,
        review_keyword_1: review_keyword_1,
        review_keyword_2: review_keyword_2,
        review_keyword_3: review_keyword_3,
        review_keyword_4: review_keyword_4,
        review_keyword_5: review_keyword_5,
        review_keyword_6: review_keyword_6,
        reviews_priority: reviewsPriority,
        request_delay_days_shipped: requestDelayDaysShipped,
        request_delay_days_pos: requestDelayDaysPOS
      }),
    };


    return aFetch(`/api/shop/`, fetchOptions).then(

      response => {
        return response.json()
      }
    ).then(() => {
      setActiveToast(true)

    })
  }


  const optionsDelay = [
    { label: '21 Days', value: '21' },
    { label: '14 Days', value: '14' },
    { label: '10 Days', value: '10' },
    { label: '7 Days', value: '7' },
    { label: '5 Days', value: '5' },
    { label: '3 Days', value: '3' },
  ];

  return (


    <Frame>
      <Page
        breadcrumbs={[{ onAction: () => { navigate("/settings") } }]}
        title="Review Collection"
        primaryAction={{ content: 'Save', onAction: () => saveShop() }}
      >

        {/* <TitleBar title="Settings" /> */}
        <Layout>
          {(activeToast ?
            <Toast content="Shop settings Saved" duration={4000} onDismiss={toggleActiveToast} /> :
            null
          )
          }

          <Layout.AnnotatedSection
            title="Klaviyo Integration"
            description={"Connect your Klaviyo account to start sending email and SMS review requests."}

          >

            <Card sectioned title='Trigger "Ready to review" flow.'>
              <FormLayout>
                <FormLayout.Group >
                  <Stack>
                <div className="single-number-textfield">
                  <TextField
                    max={200}
                    min={0}
                    type="number"
                    value={"" + requestDelayDaysShipped}
                    label="Website orders"
                    onChange={handleSelectChangeDelay}
                    autoComplete="off"
                    connectedRight={<div style={{ paddingTop: "5px", paddingLeft: "8px", paddingRight: "10px" }}>days after Delivery</div>}
                  />
                  </div>

                <div className="single-number-textfield">
                  <TextField

                    type="number"
                    max={200}
                    min={0}
                    value={"" + requestDelayDaysPOS}
                    label="Shopify POS orders"
                    onChange={handleSelectChangeDelayPOS}
                    autoComplete="off"
                    connectedRight={<div style={{ paddingTop: "5px", paddingLeft: "8px", paddingRight: "10px" }}>days after Fulfillment</div>}
                  />
                  </div>
                  </Stack>
                </FormLayout.Group>

                <FormLayout.Group >
                <div className="short-textfield">
                  <TextField
                    value={klaviyo_token}
                    label="Public API Key"
                    onChange={handleKlaviyoTokenChange}
                    autoComplete="off"
                    helpText={<div><Link external onClick={ () => { window.open(`https://www.klaviyo.com/account#api-keys-tab`, '_blank'); }}>Find key</Link></div>}

                  />
                  </div>




                </FormLayout.Group>


              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>


          <Layout.AnnotatedSection
            title="Prioritize product"
            description={"Choose which product to ask for reviews in an order."}

          >

            <Card sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <ChoiceList
                    title="Prioritize product with"
                    choices={[
                      { label: 'Least reviews', value: 'review_count' },
                      { label: 'Highest Price', value: 'price' },
                    ]}
                    selected={reviewsPriority}
                    onChange={handleReviewsPriorityChange}
                  />
                </FormLayout.Group>

              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection
            title="Collection page keywords"
            description={"Customize the keywords customers can easily tap to select."}

          >

            <Card sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <Stack vertical>
                    <TextField
                      value={review_keyword_1}
                      label="Keyword 1 (optional)"
                      onChange={(value) => setReviewKeyword1(value)}
                      autoComplete="off"
                    />
                    <TextField
                      value={review_keyword_2}
                      label="Keyword 2 (optional)"
                      onChange={(value) => setReviewKeyword2(value)}
                      autoComplete="off"
                    />
                    <TextField
                      value={review_keyword_3}
                      label="Keyword 3 (optional)"
                      onChange={(value) => setReviewKeyword3(value)}
                      autoComplete="off"
                    />
                    <TextField
                      value={review_keyword_4}
                      label="Keyword 4 (optional)"
                      onChange={(value) => setReviewKeyword4(value)}
                      autoComplete="off"
                    />
                    <TextField
                      value={review_keyword_5}
                      label="Keyword 5 (optional)"
                      onChange={(value) => setReviewKeyword5(value)}
                      autoComplete="off"
                    />
                    <TextField
                      value={review_keyword_6}
                      label="Keyword 6 (optional)"
                      onChange={(value) => setReviewKeyword6(value)}
                      autoComplete="off"
                    />

                  </Stack>

                </FormLayout.Group>

              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>

          {/* <Layout.AnnotatedSection
            title="Installation"
            description="Re-install the Store ScriptTag"
          >
            <SettingToggle
              action={{
                content: 'Install ScriptTag',
                onAction: handleInstallScriptTag,
              }}
            >





              Click this button to manually re-install our onsite integration<br />

            </SettingToggle>
          </Layout.AnnotatedSection> */}

        </Layout>
      </Page>
    </Frame>
  );
}
