import { Button, Card, Checkbox, ChoiceList, ColorPicker, FormLayout, Frame, hsbToHex, Layout, Page, Popover, rgbToHsb, TextField, Toast } from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { aFetch } from './Index'
import { CustomStar } from './Products'

export function SettingsOnsite() {

    const [activeToast, setActiveToast] = useState(false)
    const toggleActiveToast = useCallback(() => setActiveToast((activeToast) => !activeToast), [])
    const [starColor, setStarColor] = useState('#FFFFFF')
    const handleStarColorChange = useCallback((value) => setStarColor(value), [])

    let navigate = useNavigate()
    const [inheritStyling, setInheritStyling] = useState(true)
    const handleSetInheritStyling = useCallback((value) => setInheritStyling(value), [])
    const [sortOrder, setSortOrder] = useState(['relevant'])
    const [displayWithZeroStars, setDisplayWithZeroStars] = useState(true)
    const [qaEnabled, setQAEnabled] = useState(true)
    const [featuredReviewWidgetTitle, setFeaturedReviewWidgetTitle] = useState('')
    const handleSortOrderChange = useCallback((value) => setSortOrder(value), [])
    const handleZeroStarChange = useCallback((value) => setDisplayWithZeroStars(value), [])
    const handleQAEnabledChange = useCallback((value) => setQAEnabled(value), [])
    const handleFeaturedReviewWidgetTitle = useCallback((value) => setFeaturedReviewWidgetTitle(value), [])
    const [starColorPicker, setStarColorPicker] = useState(false)

    const [richSnippetsEnabled, setRichSnippetsEnabled] = useState(false)

    const hexToRgb = (hex: string) => {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b
        })

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
            ? {
                  red: parseInt(result[1], 16),
                  green: parseInt(result[2], 16),
                  blue: parseInt(result[3], 16),
              }
            : { red: 0, green: 0, blue: 0 }
    }

    const formatHexToHsb = (hex: string) => {
        return rgbToHsb(hexToRgb(hex))
    }

    useEffect(() => {
        aFetch(`/api/shop/`)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                setSortOrder(response['default_review_sort'])
                setDisplayWithZeroStars(response['display_with_zero_stars'])
                setQAEnabled(response['qa_enabled'])

                setFeaturedReviewWidgetTitle(response['featured_review_widget_title'])
                setStarColor(response['star_color'])

                setRichSnippetsEnabled(response['rich_snippets_enabled'])
            })
            .catch((e: any) => console.error(e))
    }, [])

    const saveShop = () => {
        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                default_review_sort: sortOrder,
                display_with_zero_stars: displayWithZeroStars,
                qa_enabled: qaEnabled,
                featured_review_widget_title: featuredReviewWidgetTitle,
                star_color: starColor,
                rich_snippets_enabled: richSnippetsEnabled,
            }),
        }

        return aFetch(`/api/shop/`, fetchOptions)
            .then((response) => {
                return response.json()
            })
            .then(() => {
                setActiveToast(true)
            })
    }

    return (
        <Frame>
            <Page
                breadcrumbs={[
                    {
                        onAction: () => {
                            navigate('/settings')
                        },
                    },
                ]}
                title="Onsite widgets"
                primaryAction={{ content: 'Save', onAction: () => saveShop() }}
            >
                {/* <TitleBar title="Settings" /> */}
                <Layout>
                    {activeToast ? <Toast content="Shop settings Saved" duration={4000} onDismiss={toggleActiveToast} /> : null}

                    <Layout.AnnotatedSection title="Default sort order" description={'Decide what sort order shoppers see by default.'}>
                        <Card sectioned>
                            <FormLayout>
                                <FormLayout.Group>
                                    <ChoiceList
                                        title="Sort review by"
                                        choices={[
                                            {
                                                label: 'Most relevant (recommended)',
                                                value: 'relevant',
                                            },
                                            { label: 'Most recent', value: 'recent' },
                                            { label: 'Most liked', value: 'liked' },
                                        ]}
                                        selected={sortOrder}
                                        onChange={handleSortOrderChange}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>

                    <Layout.AnnotatedSection title="Stars Badge" description={'Decide how your stars are seen by default.'}>
                        <Card sectioned>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        value={starColor}
                                        label="Star color"
                                        placeholder=""
                                        onChange={handleStarColorChange}
                                        autoComplete="off"
                                        connectedLeft={
                                            <Popover
                                                active={starColorPicker}
                                                sectioned={true}
                                                activator={
                                                    <Button
                                                        disclosure="down"
                                                        icon={<CustomStar color={starColor} size="16px" />}
                                                        onClick={() => {
                                                            setStarColorPicker(true)
                                                        }}
                                                    ></Button>
                                                }
                                                onClose={() => {
                                                    setStarColorPicker(false)
                                                }}
                                            >
                                                <Popover.Pane fixed>
                                                    <Popover.Section>
                                                        <ColorPicker onChange={(color) => setStarColor(hsbToHex(color))} color={formatHexToHsb(starColor)} />
                                                    </Popover.Section>
                                                </Popover.Pane>
                                            </Popover>
                                        }
                                    />
                                </FormLayout.Group>

                                <FormLayout.Group>
                                    <Checkbox label="Display Stars Badge when there are no reviews" checked={displayWithZeroStars} onChange={handleZeroStarChange} />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Checkbox label="Display Question & Answers" checked={qaEnabled} onChange={handleQAEnabledChange} />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>

                    <Layout.AnnotatedSection title="Google Integration" description={'Enable SEO features to improve integration with Google'}>
                        <Card sectioned>
                            <FormLayout>
                                <FormLayout.Group>
                                    <Checkbox label="Enable Google Rich Snippets Support" checked={richSnippetsEnabled} onChange={setRichSnippetsEnabled} />
                                </FormLayout.Group>


                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>

                    <Layout.AnnotatedSection title="Featured Review Carousel" description={'Decide how the onsite widgets are seen by default.'}>
                        <Card sectioned>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        value={featuredReviewWidgetTitle}
                                        label="Title text for the Featured Reviews Carousel "
                                        onChange={handleFeaturedReviewWidgetTitle}
                                        autoComplete="off"
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </Page>
        </Frame>
    )
}
