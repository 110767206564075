import {
  Card, Checkbox, FormLayout, Frame, Layout,
  Page, Select, Toast
} from '@shopify/polaris'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { aFetch } from './Index'

export function SettingsReviewManagement() {

    const [activeToast, setActiveToast] = useState(false)
    const [autoPublish, setAutoPublish] = useState(false)
    const toggleActiveToast = useCallback(() => setActiveToast((activeToast) => !activeToast), [])
    const handleChange = useCallback((newChecked) => setAutoPublish(newChecked), [])

    let navigate = useNavigate()

    useEffect(() => {
        aFetch(`/api/shop/`)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                setAutoPublish(response['auto_publish'])

                setPublishThreshhold(response['publish_threshhold'])
            })
            .catch((e: any) => console.error(e))
    }, [])

    const saveShop = () => {
        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                auto_publish: autoPublish,
                publish_threshhold: publishThreshhold,
            }),
        }

        return aFetch(`/api/shop/`, fetchOptions)
            .then((response) => {
                return response.json()
            })
            .then(() => {
                setActiveToast(true)
            })
    }

    const [publishThreshhold, setPublishThreshhold] = useState(4)

    const handleSelectChange = useCallback((value) => setPublishThreshhold(value), [])

    const options = [
        { label: '5', value: '5' },
        { label: '4', value: '4' },
        { label: '3', value: '3' },
        { label: '2', value: '2' },
        { label: '1', value: '1' },
    ]

    return (
        <Frame>
            <Page
                breadcrumbs={[
                    {
                        onAction: () => {
                            navigate('/settings')
                        },
                    },
                ]}
                title="Review moderation"
                primaryAction={{ content: 'Save', onAction: () => saveShop() }}
            >

                <Layout>
                    {activeToast ? <Toast content="Shop settings Saved" duration={4000} onDismiss={toggleActiveToast} /> : null}

                    <Layout.AnnotatedSection title="Auto-publish" description={'Publish reviews that meet the criteria without the need for manual review.'}>
                        <Card sectioned>
                            <FormLayout>
                                <FormLayout.Group>
                                    <Checkbox label="Auto publish reviews" checked={autoPublish} onChange={handleChange} />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select label="Minimum rating for auto publish" options={options} onChange={handleSelectChange} value={'' + publishThreshhold} />
                                </FormLayout.Group>

                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>

                </Layout>
            </Page>
        </Frame>
    )
}
