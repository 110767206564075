import { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Page,
  FooterHelp,
  Card,
  Link,
  Button,
  TextField,
  AccountConnection,
  TextStyle,
  CalloutCard,
  TextContainer,
  Stack,
  ButtonGroup,
  Toast,
  Frame,
  Icon,
  Badge,
  List,
  RadioButton,
} from '@shopify/polaris';
import { CircleTickMajor, ImportMinor } from '@shopify/polaris-icons';

import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { aFetch } from './Index';

import { legalAgreedAtAtom } from './License'

interface AccountProps {
  onAction(): void;
}


export const shopStatsQuery = selector({
  key: 'ShopStats',
  get: async ({ get }) => {


    var response = await aFetch(`/api/stats/`).then(
      response => {

        return response.json()
      }
    )

    return response
  },
});



const kt = atom<string>({
  key: 'kt',
  default: selector({
    key: 'kt/Default',
    get: ({ get }) => aFetch(`/api/shop/`).then(
      response => {
        return response.json()
      }
    ).then(
      responseJson => {

        if ("klaviyo_token" in responseJson && responseJson["klaviyo_token"].length == 6) {
          return "CONFIGURED"
        } else {

          return responseJson["klaviyo_token"]
        }
      }
    ),
  }),
});


export function Overview() {

  const [checkboxes, setCheckboxes] = useState([]);
  const [connected, setConnected] = useState(false);

  const [legalAgreedAt, setLegalAgreedAt] = useRecoilState(legalAgreedAtAtom);


  const navigate = useNavigate()

  useEffect(() => {

    if (legalAgreedAt == "false") {
      navigate("/license")
    }

  }, [])


  const { boot, shutdown, showNewMessages, show, showArticle } = useIntercom();


  const shopStats = useRecoilValue(shopStatsQuery);


  const [klaviyoToken, setKlaviyoToken] = useRecoilState(kt);


  const handleKlaviyoTokenChange = useCallback((value) => setKlaviyoToken(value), []);
  const handleCheckboxesChange = useCallback(
    (value) => setCheckboxes(value),
    [],
  );
  const [showTokenToast, setShowTokenToast] = useState(false)

  const toggleConnection = useCallback(() => {
    setConnected(!connected);
  }, [connected]);

  const [savingToken, setSavingToken] = useState(false);

  const [themeProcessing, setThemeProcessing] = useState(false);

  const [themeMode, setThemeMode] = useState("draft")

  const secondaryActions = [{ content: 'Import Reviews', icon: ImportMinor }];

  const saveToken = () => {
    setSavingToken(true)
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        klaviyo_token: klaviyoToken,
      }),
    };


    return aFetch(`/api/shop/`, fetchOptions).then(

      response => {
        return response.json()
      }
    ).then(() => {
      setSavingToken(false)
      setKlaviyoToken("CONFIGURED")
      setShowTokenToast(true)

    })
  }





  const handleThemeModeChange = useCallback(
    (_checked, newValue) => setThemeMode(newValue),
    [],
  );

  return (

    <Frame>
      <Page
        title="👋 Hey there! Welcome to Klaviyo Reviews."
      >

        <Layout>

          <Layout.Section fullWidth>
            <Card
              title="Theme Installation"


            >
              <Card.Section>
                <TextContainer>

                <p>Which theme would you like to install on?</p>
                  <Stack vertical>

                    <RadioButton
                      label="Live theme"
                      helpText="Changes will immediately be live."
                      checked={themeMode === 'live'}
                      id="live"
                      name="theme_mode"
                      onChange={handleThemeModeChange}
                    />
                    <RadioButton
                      label="Draft theme"
                      helpText="We will make a copy of your current theme. You will publish it manually when you're ready."
                      id="draft"
                      name="theme_mode"
                      checked={themeMode === 'draft'}
                      onChange={handleThemeModeChange}
                    />
                  </Stack>

                </TextContainer>
                <Stack distribution="trailing">
                  <ButtonGroup>
                    <Button external={true} url="https://intercom.help/klaviyo-reviews/en/articles/6187597-installing-klaviyo-reviews-on-shopify-online-store-2-0-themes">Learn More</Button>


                    <Button primary
                      disabled={themeProcessing}
                      loading={themeProcessing}
                      onClick={() => {

                      const fetchOptions = {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          install: true,
                        }),
                      };

                      setThemeProcessing(true)
                      return aFetch(`/api/themes/`, fetchOptions).then(

                        response => {
                          return response.json()
                        }
                      ).then((responseJSON) => {

                        var intervalCount = 0

                        var themeJobInterval = setInterval(() => {

                          intervalCount = intervalCount + 1

                          if (intervalCount > 200) {
                              clearInterval(themeJobInterval)
                          }
                          fetch('/api/themes/' + responseJSON['task_id'] + "/", {
                              method: 'GET'
                          }).then((response) => {
                              if (response.ok) {
                                  return response.json()
                              }

                          }).then((responseJSON) => {
                              console.log(responseJSON['status'])
                              if (responseJSON['status'] == "done") {

                                  // setResults(responseJSON['result'])
                                  clearInterval(themeJobInterval)
                                  setThemeProcessing(false)
                              } else {
                                  // setResultsProgress((responseJSON.completed_count / responseJSON.item_count) * 100.0)
                              }
                          })

                      }, 1000)


                      })
                    }}>Install</Button>
                  </ButtonGroup>
                </Stack>

              </Card.Section>

              <Card.Section >
                {showTokenToast && (
                  <Toast content="Klaviyo successfully configured" onDismiss={() => setShowTokenToast(false)} />
                )}
                <TextContainer>


                  <TextStyle variation="strong">Integrate with Klaviyo</TextStyle> {klaviyoToken == "CONFIGURED" && (<Badge status='success'>Completed</Badge>)}
                  <p>
                    Klaviyo sends messages via email and SMS, such as review requests.
                  </p>
                  <Stack distribution="fill" alignment='baseline'>
                    {klaviyoToken != "CONFIGURED" && (
                      <Stack alignment='leading'>
                        <TextField
                          maxLength={6}
                          value={klaviyoToken as string}
                          label="Klaviyo Public API Key"
                          labelAction={{ content: 'Find Key', onAction: () => { window.open(`https://www.klaviyo.com/account#api-keys-tab`, '_blank'); } }}
                          onChange={handleKlaviyoTokenChange}
                          autoComplete="off"
                          connectedRight={(<Button loading={savingToken} disabled={savingToken} primary onClick={() => saveToken()}>Save</Button>)}
                        />
                      </Stack>
                    )}
                    <Stack distribution='trailing'>
                      <div>
                        <br />
                        <Button external={true} url="https://intercom.help/klaviyo-reviews/en/articles/6188072-integrating-with-klaviyo">Learn More</Button>
                      </div>
                    </Stack>

                  </Stack>
                </TextContainer>
              </Card.Section>
              {/*

            <Card.Section>
              <TextContainer>
                <TextStyle variation="strong"> 3. Start collection reviews</TextStyle>
                <p>
                  Set up Klaviyo flows to automatically request reviews.
                </p>
                <Stack distribution="trailing">
                <ButtonGroup>
                  <Button primary url='https://www.klaviyo.com/flows' external>Setup Review Flow</Button>

                </ButtonGroup>
              </Stack>
              </TextContainer>



            </Card.Section> */}



            </Card>

          </Layout.Section>


          <Layout.Section oneHalf>
            <Card title="Reviews Collected" actions={[{ content: 'Manage Reviews and Questions', onAction: () => navigate("/reviews") }]} sectioned>



              <p style={{ fontSize: "36px", fontWeight: 400, color: (shopStats as any)["recent_review_count"] > 0 ? "#000" : "#B0BCC7", marginBottom: "1em", marginTop: "1em" }}>
                {(shopStats as any)["recent_review_count"] > 0 ? (shopStats as any)["recent_review_count"] + " Reviews Collected" : "No reviews yet"}
              </p>
              <p>
                <TextStyle variation="subdued">Total</TextStyle>
              </p>


            </Card>

          </Layout.Section>
          <Layout.Section oneHalf>
            <Card title="Average Rating" sectioned>


              <p style={{ fontSize: "36px", fontWeight: 400, color: (shopStats as any)["recent_review_count"] > 0 ? "#000" : "#B0BCC7", marginBottom: "1em", marginTop: "1em" }}>
                {(shopStats as any)["recent_review_count"] > 0 ? (shopStats as any)["average_rating"] + " stars" : "No reviews yet"}
              </p>
              <p>
                <TextStyle variation="subdued">Total</TextStyle>
              </p>


            </Card>
          </Layout.Section>

          <Layout.Section fullWidth>
            <CalloutCard

              title="Manage Review Requests"
              illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
              primaryAction={{
                content: 'Open Flows in Klaviyo',
                url: 'https://www.klaviyo.com/flows',
                external: true
              }}
              secondaryAction={{ content: 'Learn more', url: "https://intercom.help/klaviyo-reviews/en/articles/6188072-integrating-with-klaviyo", external: true }}
            >
              <p>You can customize and view performance of your flows in Klaviyo.</p>
            </CalloutCard>

          </Layout.Section>


          <Layout.Section fullWidth>
            <CalloutCard
              title="Got any questions or ideas? Let us know how we can make things better."
              illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
              primaryAction={{
                content: 'Contact us',
                onAction: showNewMessages
              }}


            >
              <p>You can customize and view performance of your flows in Klaviyo.</p>
            </CalloutCard>

          </Layout.Section>




          <Layout.Section fullWidth>
            <FooterHelp>
              For more help or support with Klaviyo Reviews please{' '}
              <Link onClick={showNewMessages}>contact us</Link>.
            </FooterHelp>
          </Layout.Section>
        </Layout>

      </Page>
    </Frame>
  );
}


function ConnectAccount({ onAction }: AccountProps) {
  return (
    <AccountConnection
      action={{ content: 'Connect', onAction }}
      details="Klaviyo not connected"
      termsOfService={
        <p>
          By clicking Connect, you are accepting Klaviyo'sTerms and Conditions,
        </p>
      }
    />
  );
}

function ReviewsModeration({ onAction }: AccountProps) {
  return (
    <AccountConnection
      action={{ content: 'View Products', onAction }}
      details="2 of your products have reviews awaiting publication"
      termsOfService={
        <p>
          View product reviews that need to be published
        </p>
      }
    />
  );
}
