import { useState, Fragment } from 'react';
import {
  Page,
  Card,
  Button,
  FormLayout,
  TextField,
  Form,
  DisplayText,
  TextContainer,
  Spinner,
} from '@shopify/polaris';

interface AccountProps {
  onAction(): void;
}

export function Install() {


  const [store, setStore] = useState('');
  const [buttonText, setButtonText] = useState('Connect');

  const handleSubmit = (e: any) => {
    e.preventDefault()

    setButtonText("Connecting...")
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        store: store,
      }),
    };


    return fetch("/shopify/login/", fetchOptions).then(response => {
      return response.json()
    }).then(response => {

      if(response["redirect_uri"]) {
        window.location.href = response["redirect_uri"]
      }
    })

  }

  const handleStoreChange = (value: any) => {setStore(value)}

  return (
    <Fragment >


      <Page
        narrowWidth

      >

        <br /><br />
        <div style={{ width: "100%", margin: "0 auto", textAlign: "center", paddingBottom: "2rem"}}>
        <img style={{ width: "60%" }} src="https://fulfilled-media.s3.amazonaws.com/logo/logo-large.png" />
        </div>
        <Card title={(<DisplayText size="large">Connect Klaviyo Reviews</DisplayText>)} sectioned>

        <Form onSubmit={handleSubmit}>
            <FormLayout>
              <p>
                Let's start by connecting Klaviyo Reviews to your Shopify store.
              </p>
              <TextField
                value={store}
                onChange={handleStoreChange}
                label=""
                type="text"
                autoComplete="off"
                autoFocus
                suffix=".myshopify.com"
                placeholder='Enter your Shopify Store Name'

              />

              <Button primary fullWidth submit loading={buttonText != "Connect"}>{buttonText}</Button>

            </FormLayout>
          </Form>

        </Card>



      </Page>

    </Fragment>
  );
}
