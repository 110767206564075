import { TopBar, Frame } from "@shopify/polaris";
import { ArrowLeftMinor } from "@shopify/polaris-icons";
import React, { Fragment } from "react";
import { useState, useCallback, useEffect } from "react";
import { atom, selector, useRecoilValue } from "recoil";
import { AppNav } from "./AppNav";



const currentUserNameQuery = selector({
    key: 'CurrentUser',
    get: async ({ get }) => {
        console.log("fetching")
        return fetch(`/api/users/current/`).then(

            response => {
                if (response.ok) {
                    return response.json()
                } else {
                    window.location.href = "/login"
                }
            }
        ).catch((reason) => {


        })
    },
});

function AppTopBarMenu(props: any) {

    // const currentUser = useRecoilValue(currentUserNameQuery);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const toggleIsUserMenuOpen = useCallback(
        () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
        [],
    );

    return (
        <TopBar.UserMenu
            actions={[
                {
                    items: [{ content: 'Back to Shopify', icon: ArrowLeftMinor }],
                },
                {
                    items: [{ content: 'Community forums' }],
                },
            ]}
            name={"Jeff Mancuso"}
            detail="Klaviyo"
            initials={"JM"}
            open={isUserMenuOpen}
            onToggle={toggleIsUserMenuOpen}
        />

    )
}

function AppTopBar(props: any) {

    return (

        <TopBar
            showNavigationToggle
            userMenu={(<React.Suspense fallback={(<div></div>)}>
                <AppTopBarMenu />
            </React.Suspense>)}
        />

    )
}
export function AppFrame(props: any) {

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const logo = {
        width: 110,
        topBarSource:
            'https://fulfilled-media.s3.amazonaws.com/logo/logo-light.png',
        url: 'http://fulfilled.reviews',
        accessibilityLabel: 'Klaviyo Reviews',
    };

    // return (
    //     <AppNav>{props.children}</AppNav>
    // )
    // inIframe()
    //   ? (<AppNav>{props.children}</AppNav>)

    if (!inIframe) {
        return ((<Frame logo={logo} navigation={(<AppNav />)} topBar={(<AppTopBar />)}>
            {props.children}
        </Frame>))
    } else {
        return (<AppNav>{props.children}</AppNav> )
    }




    // )

}
