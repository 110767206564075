import { Frame, Navigation, Tabs } from "@shopify/polaris";
import { HomeMajor, ProductsMajor, OrdersMajor, FavoriteMajor, SettingsMajor, ProductsMinor, OrdersMinor, SettingsMinor, HomeMinor } from "@shopify/polaris-icons";
import React, { useState, useCallback, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function AppNav(props: any) {
    let navigate = useNavigate();


    const location = useLocation();

    const tabs2 = () => {

        return [
            {
                id: 'dashboard',
                content: 'Dashboard',
                icon: HomeMinor,
                label: 'Dashboard',

            },
            {
                id: 'products',
                content: 'Products',
                icon: ProductsMinor,
                label: 'Products',
            },
            {
                id: 'orders',
                content: 'Orders',
                icon: OrdersMinor,
                label: 'Orders',

            },
            {
                id: 'reviews',
                content: 'Reviews',
                icon: FavoriteMajor,
                label: 'Reviews',
            },
            {
                id: 'settings',
                content: 'Settings',
                icon: SettingsMinor,
                label: 'Settings',
            },
        ];
    }

    const [selected, setSelected] = useState(tabs2().findIndex((element) => location.pathname.startsWith("/" + element.id)));



    const tabs = () => {

        return [
            {
                id: 'dashboard',
                content: 'Dashboard',
                label: 'Dashboard',
                panelID: 'dashboard',
                icon: HomeMajor,
                shouldResizeIcon: true,
                selected: (location.pathname == "/dashboard"),
                onClick: () => {
                    navigate("/dashboard")
                }
            },
            {
                id: 'products',
                content: 'Products',
                label: 'Products',

                panelID: 'products',

                icon: ProductsMajor,
                shouldResizeIcon: true,
                selected: (location.pathname.startsWith("/products")),
                onClick: () => {
                    alert("products");
                    navigate("/products")
                }

            },

            {
                id: 'reviews',
                content: 'Reviews',
                label: 'Reviews',
                accessibilityLabel: 'Reviews',
                icon: FavoriteMajor,
                shouldResizeIcon: true,
                panelID: 'reviews',
                selected: (location.pathname.startsWith("/reviews")),
                onClick: () => {
                    navigate("/reviews")
                }
            },
        ];
    }



    const settingsTabs = [
        {
            id: 'settings',
            content: 'Settings',
            label: 'Settings',
            accessibilityLabel: 'Settings',
            icon: SettingsMajor,
            shouldResizeIcon: true,
            panelID: 'settings',
            selected: (location.pathname.startsWith("/settings")),
            onClick: () => {
                navigate("/settings")
            }


        }
    ];


    // React.useEffect(() => {

    //     console.log(location.pathname)
    //     const found = tabs.findIndex(tab => {
    //         if (location.pathname == "/") {
    //             return true;
    //         } else {
    //             return tab.id != "dashboard" && location.pathname.startsWith(tab['link'])
    //         }
    //     });

    //     setSelected(found);
    // }, [location]);


    const handleTabChange = useCallback(
        (selectedTabIndex) => {
            setSelected(selectedTabIndex)
            navigate("/" + tabs2()[selectedTabIndex]["id"])
        }
        ,
        [],
    );


    return (
        <Fragment>
            {/* {inIframe() && ( */}
                {/* <Tabs tabs={tabs2()} selected={selected} onSelect={handleTabChange} >{props.children}</Tabs> */}
                {props.children}
                {/* )} */}
            {/* {!inIframe() && (
                <Frame>
                    <Navigation location={location.pathname}>
                        <Navigation.Section items={tabs2()} />
                    </Navigation>
                    {props.children}
                </Frame>
            )} */}
        </Fragment>
        // : (<Navigation location={location.pathname}>
        //     <Navigation.Section
        //         fill={true}
        //         items={tabs()} />
        //     <Navigation.Section

        //         items={settingsTabs} />

        // </Navigation>)
        // )
    )


}
